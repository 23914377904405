<template>
    <v-card style="height: 100%; min-height: 350px;" class="chart-card">
        <DarkOverlayLoader v-if="ventasPorAnio.isLoading" />
        <v-card-text class="chart-card-scroller">
            <div class="chart-card-content">
                <apexchart type="bar" height="350" :options="options" :series="series" />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { toMoneyFormat } from '@/utils/data';
import { obtenerNombreDeMes } from '@/utils/datetime';
import { DarkOverlayLoader } from '@/components/utils';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'MontosPorAnio',
    components: { DarkOverlayLoader },
    computed: {
        ...mapState("cmReporteria", ['ventasPorAnio']),
        nombresMeses() {
            const meses = Array.from({ length: 12 });

            return meses.map((_, index) => obtenerNombreDeMes(index + 1));
        },
        series() {
            return this.ventasPorAnio.data.map((convenio) => {
                return ({
                    name: convenio.nombre,
                    group: convenio.codigo,
                    data: convenio.detalle_anual.map((monto) => monto.monto),
                });
            });
        },
        options() {
            return ({
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                title: {
                    text: `Montos año ${this.anio ?? 'actual'}`,
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: this.nombresMeses,
                },
                fill: {
                    opacity: 1
                },
                yaxis: {
                    labels: {
                        formatter: (valor) => {
                            return toMoneyFormat(valor);
                        },
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        formatter: (value) => {
                            const suffix = this.anio ? ` - ${this.anio}` : ' - año actual';

                            return `${value}${suffix}`;
                        }
                    },
                    shared: true,
                    intersect: false,
                },
            });
        },
    },
    methods: {
        ...mapActions("cmReporteria", ['cargarVentasPorAnio']),
    },
    created() {
        this.cargarVentasPorAnio();
    },
}
</script>
<style>
@media (max-width: 1264px) {
    .chart-card {
        max-width: 100%;
        overflow: hidden !important;
    }

    .chart-card-scroller {
        overflow: auto;
        max-width: 100%;
    }
    
    .chart-card-content {
        min-width: 1000px;
    }
}
</style>